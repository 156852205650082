// ***** FOOTER ***** //
function renderFooterPaymentIcons(){
    const container = document.getElementsByClassName("footer1-payment-icons");

    for(let j = 0; j < container.length; j++){
        for(let i = 0; i < lang['QUANTITY_PAYMENT_ICONS']; i++){
            let itemLi = document.createElement("li");
            let itemImg = document.createElement("img");

            // itemLi.classList.add('list-payment__item');
            itemLi.classList.add('paymentli' + (i + 1));

            itemImg.setAttribute('src', lang['IMG_CART_PAYMENT_' + (i + 1) ]);

            itemLi.appendChild(itemImg)
            container[j].appendChild(itemLi);
        }
    }
}

//footer link modal
jQuery('.modal-link').on('click', function(e) {
    e.preventDefault();
    var popid=jQuery(this).attr("data-id");
    jQuery('#'+popid).css('display', 'block');
});

//footer link modal
$(document).on('click', '.footer1-modal', function (e) {
    if ($(e.target).is('.footer1-modal-content-info, .footer1-modal-content-info *')) return;
    $('.footer1-modal').hide();
    $('html').css({
        "margin-right": " 0",
        "overflow": "visible"
    })
});